import React from 'react'

function NameLogo() {
  return (
    <pre className='text-cyan-50 text-xs lg:text-lg'>
          {`
          __      _               _   _                     
          / _\\ ___| |__   __ _ ___| |_(_) __ _ _ __          
          \\ \\ / _ \\ '_ \\ / _\` / __| __| |/ _\` | '_ \\         
          _\\ \\  __/ |_) | (_| \\__ \\ |_| | (_| | | | |        
          \\__/\\___|_.__/ \\__,_|___/\\__|_|\\__,_|_| |_|        
                                                                
          __ _              _    _               _          
          / _\\ |_ __ _ _ __ | | _(_) _____      _(_) ___ ____ 
          \\ \\| __/ _\` | '_ \\| |/ / |/ _ \\ \\ /\\ / / |/ __|_  /
          _\\ \\ || (_| | | | |   <| |  __/\\ V  V /| | (__ / / 
          \\__/\\__\\__,_|_| |_|_|\\_\\_|\\___| \\_/\\_/ |_|\\___/___|
                                                                
          `}
    </pre>
  )
}

export default NameLogo